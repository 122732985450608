import {
	IContactMethodType,
	ICountry,
	ILevel,
	IPhoneNumberCountry,
	ITimezone,
	IUser,
	IUserLanguage,
	IUserType
} from './user.interface';
import { IAvatar } from './avatar.interface';
import { environment } from '../../../environments/environment';
import { from, Observable, of, switchMap } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { format } from 'date-fns';

interface IBalance {
	current?: number;
	potential?: number;
	earned?: number;
	spent?: number;
}

interface IUserLevel {
	id: number;
	leaves: number;
	start: number;
	end: number;
}

enum UserTypes {
	PA = 'PA', // Ganatron
	PR = 'PR', // Ganasocio
	CO = 'CO', // Ganagente
	RE = 'RE', // Ganaquipo
	MU = 'MU', // Ganarbol
	MX = 'MX', // Ganacuate
	SD = 'SD' // Seed
}

export class User {
	public get id(): number {
		return this._id;
	}
	public get userObj(): IUser {
		return this._userObj;
	}
	public get fullName(): string {
		return this.firstName + ' ' + this._lastName;
	}

	public get firstName(): string {
		return this._firstName;
	}

	public get lastName(): string {
		return this._lastName;
	}

	public get isSpecial(): boolean {
		const specialTypes: UserTypes[] = [UserTypes.PR, UserTypes.CO, UserTypes.RE, UserTypes.PA];
		return specialTypes.includes(this._userType?.code as UserTypes);
	}

	public get initials(): string {
		const firstNameInitial = this.firstName.charAt(0).toUpperCase();
		const lastNameInitial = this.lastName.charAt(0).toUpperCase();
		return `${firstNameInitial}${lastNameInitial}`;
	}

	public get profileColor(): string {
		return this._profileColor;
	}

	public get totalReferrals(): number {
		return +this._totalReferrals || 0;
	}

	public get spentMoney(): number {
		return +this._spentMoney || 0;
	}

	public get okeeyCashAccountNumber(): string {
		return this._okeeyCashAccountNumber;
	}

	public get avatar(): IAvatar {
		return this._avatar;
	}
	public get avatarUrl(): string {
		return environment.avatarsUrl + 'Property_1=' + this.avatar?.image;
	}

	public get userLanguageCode(): string {
		return this._userLanguage?.code || 'es';
	}

	public get referralCode(): string {
		return this._referralCode;
	}

	public get ganamigosSince(): string {
		return this._ganamigosSince;
	}
	public get formattedGanamigosSince(): string {
		return format(new Date(this._ganamigosSince), 'dd/MM/yyyy');
	}

	public get email(): string {
		return this._email;
	}
	public get phoneNumber(): string {
		return this._phoneNumber;
	}
	public get address(): string {
		return [this._city, this._country?.name_en].filter(Boolean).join(', ');
	}
	public get fullPhoneNumber(): string {
		return this._phoneNumberCountry?.phone_code + this._phoneNumber;
	}
	public get phoneNumberCountryCode(): string {
		return this._phoneNumberCountry?.phone_code;
	}

	public get isVisible(): boolean {
		return this._isVisible;
	}

	public get levelNumber(): number {
		return this._level?.id || 1;
	}

	public get streetAddress(): string {
		return this._streetAddress;
	}

	public get isEligible(): boolean {
		return this._isEligible;
	}

	public get okeeyCashInternalUserId(): number {
		return this._okeeyCashInternalUserId;
	}

	public get totalGanarbols(): number {
		return this._totalGanarbols || 0;
	}

	public get totalGanacuates(): number {
		return this._totalGanacuates || 0;
	}

	public get bankedEligibility(): number {
		return this._bankedEligibility || 0;
	}

	public get isUsMember(): boolean {
		return this._userType?.code !== UserTypes.MX;
	}

	public get typeName(): string {
		return this._userType?.name || '';
	}

	public get balance(): IBalance {
		return {
			current: +this._balance,
			potential: +this._potentialBalance,
			earned: +this._totalEarnedBalance,
			spent: +this._totalSpentBalance
		};
	}

	public get level(): IUserLevel {
		return {
			id: this.levelNumber,
			leaves: this._leaves,
			start: +this.start_balance,
			end: +this.end_balance
		};
	}

	public get branches(): number {
		return this._branches;
	}
	public get leaves(): number {
		return this._leaves;
	}
	public get rings(): number {
		return this._rings;
	}

	// Main Info
	private readonly _id?: number;
	private readonly _avatar?: IAvatar;
	private readonly _firstName?: string;
	private readonly _lastName?: string;
	private readonly _email?: string;
	private readonly _userObj: IUser;
	private readonly _userLanguage: IUserLanguage;
	private readonly _okeeyCashAccountNumber: string;
	private readonly _profileColor: string;

	// Personal Info
	private readonly _state?: string | null;
	private readonly _city?: string | null;
	private readonly _gender?: string | null;
	private readonly _phoneNumberCountry?: IPhoneNumberCountry;
	private readonly _phoneNumber?: string;
	private readonly _fullName?: string | null;
	private readonly _timezone?: ITimezone;
	private readonly _country?: ICountry;
	private readonly _contactMethodType?: IContactMethodType;
	private readonly _referralCode?: string;
	private readonly _streetAddress?: string;

	// User Settings and Status
	private readonly _userType?: IUserType;
	private readonly _level?: ILevel;
	private readonly _isVisible?: boolean;
	private readonly _isEligible?: boolean;
	private readonly _okeeyCashInternalUserId?: number;
	private readonly _totalGanarbols?: number;
	private readonly _totalGanacuates?: number;
	private readonly _bankedEligibility?: number;
	private readonly _status?: string;
	private readonly _branches?: number;
	private readonly _leaves?: number;
	private readonly _rings?: number;
	private readonly end_balance: string;
	private readonly start_balance: string;
	private readonly _ganamigosSince: string;

	// Balance
	private readonly _balance?: string;
	private readonly _potentialBalance?: string;
	private readonly _totalEarnedBalance?: string;
	private readonly _totalSpentBalance?: string;
	private readonly _totalReferrals?: number;
	private readonly _spentMoney?: number;

	private _reloadFn: () => Observable<User>;

	constructor(user: IUser) {
		this._userObj = user;
		this._id = user.id;
		this._email = user.email;
		this._state = user.state;
		this._city = user.city;
		this._gender = user.gender;
		this._phoneNumberCountry = user.phone_number_country;
		this._phoneNumber = user.phone_number;
		this._avatar = user.avatar;
		this._firstName = user.first_name;
		this._lastName = user.last_name;
		this._fullName = user.full_name;
		this._timezone = user.timezone;
		this._country = user.country;
		this._contactMethodType = user.contact_method_type;
		this._balance = user.balance;
		this._potentialBalance = user.potential_balance;
		this._totalEarnedBalance = user.total_earned_balance;
		this._totalSpentBalance = user.total_spent_balance;
		this._referralCode = user.referral_code;
		this._userType = user.user_type;
		this._level = user.level;
		this._isVisible = user.is_visible;
		this._isEligible = user.is_eligible;
		this._okeeyCashInternalUserId = user?.okeey_cash_internal_user_id;
		this._totalReferrals = +user?.total_referrals;
		this._spentMoney = user?.spent_money;
		this._totalGanarbols = user.total_gana_arboles;
		this._totalGanacuates = user.total_gana_cuates;
		this._bankedEligibility = user.banked_eligibility;
		this._ganamigosSince = user.ganamigos_since;
		this._status = user.status;
		this._branches = user.branches;
		this._leaves = user.leaves;
		this._rings = user.rings;
		this._userLanguage = user.user_language;
		this.start_balance = user?.level?.start_balance;
		this.end_balance = user?.level?.end_balance;
		this._okeeyCashAccountNumber = user?.okeey_cash_account_number;
		this._streetAddress = user.street_address;
		this._profileColor = user?.profile_color;
	}

	public copyReferralCode(): Observable<boolean> {
		const text: string = this.referralCode;
		if (navigator?.clipboard && navigator?.clipboard?.writeText) {
			return from(navigator?.clipboard?.writeText(text)).pipe(
				switchMap(() => of(true)),
				catchError(() => of(false))
			);
		} else {
			return this.fallbackCopyTextToClipboard(text);
		}
	}

	private fallbackCopyTextToClipboard(text: string): Observable<boolean> {
		const textarea: HTMLTextAreaElement = this.createTextArea(text);
		document.body.appendChild(textarea);
		return of(this.createTextArea(text)).pipe(
			switchMap(textarea => {
				document.body.appendChild(textarea);
				textarea.select();
				const success: boolean = document.execCommand('copy');
				return of(success);
			}),
			catchError(error => {
				return of(false);
			}),
			finalize(() => {
				document.body.removeChild(textarea);
			})
		);
	}

	private createTextArea(text: string): HTMLTextAreaElement {
		const textarea: HTMLTextAreaElement = document.createElement('textarea');
		textarea.value = text;
		textarea.style.position = 'fixed';
		textarea.style.opacity = '0';
		return textarea;
	}
}
